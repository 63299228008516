import React from "react";
import CurrencyFormat from "react-currency-format";

export default function CustomList({
  result,
  inputValueMoblie,
  setInputValueMoblie,
  handleSubmitCefProjectMobile,
}) {
  const handleChange = (e) => {
    setInputValueMoblie(e.target.value);
  };
  return (
    <ul className="bg-gray-50">
      <div
        style={{
          border: "1px solid #1b5fa8",
        }}
      >
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            {" "}
            <strong> Prime</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.amount1}
              thousandSeparator={true}
              prefix={"€"}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>Cef Initiale</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cef_initial}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> Cef Projet</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cef_projet1}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          {/* <form
            style={
              {
                // textAlign: "right",
              }
            }
            onSubmit={handleSubmitCefProjectMobile}
          >
            <input
              style={{
                // width: "50%",
                // backgroundColor: "transparent",
                // border: "none",
                // color: "#111827",
                // padding: "0",
                // margin: "0",
                // transition: "border-color 0.2s",
                all: "inherit",
                maxWidth: 40,
                textAlign: "center",
              }}
              id="cef_projet1"
              name="cef_projet1"
              type="number"
              min={0}
              placeholder="Cef..."
              value={inputValueMoblie}
              onChange={handleChange}
            />
          </form> */}
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> CEP projet</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cep_projet1}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong> Shab</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.shab}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong> B</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.b1}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>CUMAC</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cumac1}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>SC1: &nbsp;</strong>
          </span>
          <span> Scénario preferentiel : PAC / Ballon / 101 ET/OU 103</span>
        </li>
      </div>

      <div
        style={{
          border: "1px solid #1b5fa8",
          marginTop: "1rem",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }}
      >
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong> Prime</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.amount2}
              thousandSeparator={true}
              prefix={"€"}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>Cef Initiale</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cef_initial2}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>Cef Projet</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cef_projet2}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> CEP projet</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cep_projet2}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>Shab</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.shab}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>B</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.b2}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>CUMAC</strong>
          </span>
          <span>
            <CurrencyFormat
              value={result?.cumac2}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>

        <li className="flex justify-between px-6 py-2 text-xs text-gray-500">
          <span>
            <strong>SC2:&nbsp;</strong>
          </span>
          <span> Scénario BBC = PAC + BALLON + 102</span>
        </li>
      </div>
    </ul>
  );
}
