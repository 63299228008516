import { Button } from "@mui/material";
import { useEffect } from "react";
import "./styled/animation.css";
import CategoryForm from "./CustomForm/CategoryForm";
import CustomForm from "./CustomForm/CustomForm";
import ListChaufForm from "./CustomForm/ListChaufForm";

export function StepsFunction({
  index,
  handleSelectedData,
  selectedData,

  formData,
  setFormData,
  selectedOptionDropMain,
  setSelectedOptionDropMain,
  selectedOptionDropD,
  setSelectedOptionDropD,
  selectedOptionMiton,
  setSelectedOptionMiton,
  selectedOptionMitonTypeFen,
  setSelectedOptionMitonTypeFen,
  customFormData,
  setcustomFormData,

  selectedOptionIsolationMur,
  setSelectedOptionIsolationMur,
  selectedOptionIsolationPlan,
  setSelectedOptionIsolationPlan,
  selectedOptionIsolationCom,
  setSelectedOptionIsolationCom,
  selectedOptionIChaufageCom,
  setSelectedOptionIChaufageCom,
  valorisation,
  setValorisation,
  isolationData,
  setIsolationData,
}) {
  const handleChangeData = (e) => {
    e.preventDefault();
    if (e.target.name === "tva")
      handleSelectedData(e.target.name, e.target.value);
    else {
      handleSelectedData(e.target.name, e.target.id);
      // handleNext()
    }
  };
  // useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  const conditional = (state, value) => {
    return state === value;
  };
  const handleCategoryForm = (value) => {
    setFormData(value);
  };
  // eslint-disable-next-line default-case
  switch (index) {
    case 0:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <CustomForm
            selectedOptionDropMain={selectedOptionDropMain}
            setSelectedOptionDropMain={setSelectedOptionDropMain}
            selectedOptionDropD={selectedOptionDropD}
            setSelectedOptionDropD={setSelectedOptionDropD}
            customFormData={customFormData}
            setcustomFormData={setcustomFormData}
            selectedOptionIChaufageCom={selectedOptionIChaufageCom}
            setSelectedOptionIChaufageCom={setSelectedOptionIChaufageCom}
            valorisation={valorisation}
            setValorisation={setValorisation}
          />
        </div>
      );
    case 1:
      return (
        <div key={index} className={"fadeIn my-10 flex justify-around"}>
          <CategoryForm
            // selectedOptionIsolationMur={selectedOptionIsolationMur}
            // setSelectedOptionIsolationMur={setSelectedOptionIsolationMur}
            // selectedOptionIsolationPlan={selectedOptionIsolationPlan}
            // setSelectedOptionIsolationPlan={setSelectedOptionIsolationPlan}
            // selectedOptionIsolationCom={selectedOptionIsolationCom}
            // setSelectedOptionIsolationCom={setSelectedOptionIsolationCom}
            isolationData={isolationData}
            setIsolationData={setIsolationData}
          />
        </div>
      );
    // case 2:
    //   return (
    //     <div key={index} className={"fadeIn my-10 flex justify-around"}>
    //       <ListChaufForm
    //         selectedOptionMiton={selectedOptionMiton}
    //         setSelectedOptionMiton={setSelectedOptionMiton}
    //         selectedOptionMitonTypeFen={selectedOptionMitonTypeFen}
    //         setSelectedOptionMitonTypeFen={setSelectedOptionMitonTypeFen}
    //       />
    //     </div>
    //   );
    // case 3:
    //   return (
    //     <div key={index} className={"fadeIn  my-16 flex justify-around"}></div>
    //   );
  }
}
