import React from "react";
import { Route, Routes } from "react-router-dom";
// import BarTh145 from "./pages/BarTh145";
// import BarTh164 from "./pages/BarTh164";
import Home from "./pages/Home";
import Ebs from "./pages/Ebs";
import Vertigo from "./pages/Vertigo";

function App() {
  //console.log(progress);
  return (
    <div className="flex flex-col h-screen bg-gray-100 ">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/about" element={<About />} /> */}
        <Route path="/ebs" element={<Ebs />} />
        <Route path="/vertigo" element={<Vertigo />} />
        {/* <Route
          path="/145"
          element={<BarTh145 progress={progress} setProgress={setProgress} />}
        /> */}
        {/* <Main progress={progress} setProgress={setProgress}></Main> */}
      </Routes>
    </div>
  );
}

export default App;
